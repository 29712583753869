import {
  Button,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CheckCircle,
  Help,
  HighlightOff,
  ThumbUp,
} from '@material-ui/icons';
import Axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useStepsStyles} from '../useStepsStyles';

export function ChooseSolutionComponent({
  meter,
  setMeter,
  setActiveStep,
  isMeterOwner,
  user,
}) {
  const classes = useStepsStyles(meter);

  const [sponsor, setSponsor] = useState({});

  const [otherText, setOtherText] = React.useState(meter.experimentText);

  const [onSuccess, setOnSuccess] = React.useState(false);

  const isDisabled =
    !isMeterOwner ||
    !otherText

  useEffect(() => {
    if (!user.school) return;
    const getUserSponsor = async () => {
      const userSchool = await Axios.get(`/api/school/${user.school}`);
      const userSponsor = await Axios.get(
        `/api/sponsor/${userSchool.data.sponsor}`,
      );
      setSponsor(userSponsor.data);
    };
    getUserSponsor();
  }, [user.school]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newMeter = {
      ...meter,
      currentStep: 2,
      experimentText: otherText
    };

    try {
      const result = await Axios.post(`/api/meter/${meter._id}`, newMeter);

      setMeter(result.data);

      if (result.status === 200) {
        setOnSuccess(true);
      }
      setTimeout(() => {
        setActiveStep((prevState) => prevState + 1);
      }, 600);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
      <Typography>First, <Link href={sponsor.solutionFormUrl} target="_blank">click here</Link> to find the best strategies for your home.</Typography>
      <Typography>Second, summarize below the strategy that you will test.</Typography>
        <TextField
          label="Which variable(s), strategies, will you test and how? Why do you hypothesize they will be effective in your situation?"
          name="otherText"
          value={otherText}
          onChange={(e) => setOtherText(e.target.value)}
          variant="outlined"
          size="small"
          minRows={3}
          fullWidth={true}
          multiline
          disableGutters
        />
        <br />
        <br />
        <Button
          type="submit"
          value="updatedMeter"
          className={classes.root}
          variant="outlined"
          disabled={isDisabled}
        >
          {isDisabled ? (
            <HighlightOff style={{ color: 'grey' }} />
          ) : onSuccess ? (
            <ThumbUp style={{ color: 'white' }} />
          ) : (
            <CheckCircle style={{ color: 'white' }} />
          )}
        </Button>
        <Button
          className={classes.root}
          variant="outlined"
          component="a"
          href="https://meterhero.com/choose"
          target="_blank"
          rel="noopener"
        >
          <Help style={{ color: 'white' }} />
        </Button>
      </form>
    </React.Fragment>
  );
}

// export default ChooseSolutionComponent;
