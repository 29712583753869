import React, {Fragment} from 'react';
import SetUintTypeComponent from './SetUintTypeComponent';
import PredictUsageComponent from './PredictUsageComponent';
import CollectBaseLineComponent from './CollectBaseLineComponent';
import ChooseSolutionComponent from './ChooseSolutionComponent';
import DesignExperimentComponent from './DesignExperimentComponent';
import MeasureExperimentComponent from './MeasureExperimentComponent';
import ConstructEvidenceComponent from './ConstructEvidenceComponent';
import ChooseMainSourceDataComponent from './ChooseMainSourceDataComponent';

export const getSteps = () => {
  return [
    'Predict your average daily usage',
    'Choose strategies to test',
    'Choose your best source of data',
    'Data source units',
    'Collect baseline data',
    'Design your inquiry',
    "Measure experiment's effect",
    'Communicate your findings',
  ];
};

export const getStepContent = (
  step,
  {
    meter,
    setMeter,
    setActiveStep,
    disableDataCollectionBtn,
    setDisableDataCollectionBtn,
    isMeterOwner,
  },
  user,
) => {
  switch (step) {
    case 0:
      return (
        <Fragment>
          <PredictUsageComponent
            meter={meter}
            setMeter={setMeter}
            setActiveStep={setActiveStep}
            isMeterOwner={isMeterOwner}
            user={user}
          />
        </Fragment>
      );
    case 1:
      return (
        <Fragment>
          <ChooseSolutionComponent
            meter={meter}
            setMeter={setMeter}
            setActiveStep={setActiveStep}
            disableDataCollectionBtn={disableDataCollectionBtn}
            isMeterOwner={isMeterOwner}
            user={user}
          />
        </Fragment>
      );
    case 2:
      return (
        <Fragment>
          <ChooseMainSourceDataComponent
            meter={meter}
            setActiveStep={setActiveStep}
            setMeter={setMeter}
            disableDataCollectionBtn={disableDataCollectionBtn}
            setDisableDataCollectionBtn={setDisableDataCollectionBtn}
            isMeterOwner={isMeterOwner}
          />
        </Fragment>
      );
    case 3:
      return (
        <Fragment>
          <SetUintTypeComponent
            meter={meter}
            setMeter={setMeter}
            setActiveStep={setActiveStep}
            isMeterOwner={isMeterOwner}
          />
        </Fragment>
      );
    case 4:
      return (
        <Fragment>
          <CollectBaseLineComponent
            meter={meter}
            setActiveStep={setActiveStep}
            setMeter={setMeter}
            disableDataCollectionBtn={disableDataCollectionBtn}
            isMeterOwner={isMeterOwner}
          />
        </Fragment>
      );
    case 5:
      return (
        <Fragment>
          <DesignExperimentComponent
            meter={meter}
            setMeter={setMeter}
            setActiveStep={setActiveStep}
            disableDataCollectionBtn={disableDataCollectionBtn}
            isMeterOwner={isMeterOwner}
          />
        </Fragment>
      );
    case 6:
      return (
        <Fragment>
          <MeasureExperimentComponent
            meter={meter}
            setMeter={setMeter}
            setActiveStep={setActiveStep}
            disableDataCollectionBtn={disableDataCollectionBtn}
            isMeterOwner={isMeterOwner}
          />
        </Fragment>
      );
    case 7:
      return (
        <Fragment>
          <ConstructEvidenceComponent
            meter={meter}
            setMeter={setMeter}
            setActiveStep={setActiveStep}
            disableDataCollectionBtn={disableDataCollectionBtn}
            isMeterOwner={isMeterOwner}
          />
        </Fragment>
      );
    default:
      return 'Unknown Step';
  }
};
